export default [
  {
    text: "<p class='pri'>森林健康，海就健康；</p><p class='sec'>植物健康，人就安康。</p>",
    voiceFileUrl: require("./audios/1-2.m4a"),
  },
  {
    text: "<p class='pri'>充滿疑惑的時候就讓植物包圍吧！</p><p class='sec'>雖然也不會獲得解答。</p>",
    voiceFileUrl: require("./audios/2.m4a"),
  },
  {
    text: "<p class='pri'>我才不是什麼植物醫生呢，</p><p class='sec'>我跟植物只是互相照顧而已。</p>",
    voiceFileUrl: require("./audios/3.m4a"),
  },
  {
    text: "<p class='pri'>ㄧ直以來我都有一個夢想，</p><p class='sec'>我想聽到植物說話。</p>",
    voiceFileUrl: require("./audios/4.m4a"),
  },
  {
    text: "<p class='pri'>常常聽到人家說哪邊的土會黏人，</p><p class='sec'>但你不覺得，有植物的土都很黏人嗎？</p>",
    voiceFileUrl: require("./audios/5.m4a"),
  },
  {
    text: "<p class='pri'>俗話說：澆水澆的好，人生沒煩惱</p>",
    voiceFileUrl: require("./audios/6.m4a"),
  },
  {
    text: "<p class='pri'>植物養到收盆是難免，</p><p class='sec'>可以難過但不要難過太久～</p>",
    voiceFileUrl: require("./audios/7.m4a"),
  },
  {
    text: "<p class='pri'>貓派還狗派？</p><p class='sec'>我的寵物是植物不行嗎？植物派！</p>",
    voiceFileUrl: require("./audios/8.m4a"),
  },
  {
    text: "<p class='pri'>選一塊你附近的山，常去串門子，</p><p class='sec'>會發現季節有多麼迷人。</p>",
    voiceFileUrl: require("./audios/9.m4a"),
  },
  {
    text: "<p class='pri'>等公車很無聊的話，</p><p class='sec'>怎麼不跟旁邊的樹說說話呢？</p>",
    voiceFileUrl: require("./audios/10.m4a"),
  },
  {
    text: "<p class='pri'>來點文青的～不知道為什麼，</p><p class='sec'>總覺得有植物在，什麼都會好一點。</p>",
    voiceFileUrl: require("./audios/11.m4a"),
  },
];
