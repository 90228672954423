import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import _shuffle from "lodash/shuffle";

import AudioPreloadList from "../../../AudioPreloadList";
import FullScreenVideoBgWrapper from "../../../FullScreenVideoBgWrapper";
import SunshineText from "./SunshineText";

import sty from "./PlantsLight.module.scss";

import originData from "./data.js";

const data = _shuffle(originData);

document.querySelector('#wrap').style.height = window.innerHeight + 'px';

const InnerSize = {
  width: window.innerWidth + 'px',
  height: window.innerHeight + 'px',
  overflow: 'hidden'
};

const PlantsLight = ({ children }) => {
  const [isMute, setIsMute] = useState(false);
  const [nowIndex, setNowIndex] = useState(0);
  const onComplete = () =>
    setNowIndex((prevState) =>
      prevState === data.length - 1 ? 0 : prevState + 1
    );

  return (
    <FullScreenVideoBgWrapper videoSourceUrl={require("../../../../images/project_d/planting.mp4")} 
    // youTubeVideoId="fAfRcTQpk9U"
    >
      <AudioPreloadList list={data} />
      <div className={sty.PlantsLight} style={InnerSize}>
        <div className={sty.box__sentences}>
          <SunshineText
            className={sty.SunshineText}
            text={data[nowIndex].text}
            voiceFileUrl={data[nowIndex].voiceFileUrl}
            onComplete={onComplete}
            isMute={isMute}
          />
        </div>
      </div>
    </FullScreenVideoBgWrapper>
  );
};

PlantsLight.propTypes = {};

export default PlantsLight;
