import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { gsap, Expo, Linear } from "gsap";
import ReactHowler from "react-howler";

import sty from "./SunshineText.module.scss";

import SplitText from "../../../../../../lib/SplitText.js";

var classes = ['l_a', 'l_b', 'l_c', 'l_d', 'l_e'];
const SunshineText = ({
  className,
  text,
  voiceFileUrl,
  onComplete,
  isMute,
}) => {
  let split = {};
  const howlerPlayer = useRef(null);
  const refText = useRef(null);
  const [isHowlerPlay, setIsHowlerPlay] = useState(false);
  const quote_item = document.querySelector('#quote_item');
  const quote_first = document.querySelector('.pri');
  const quote_last = document.querySelector('.sec');
  // const pause = document.querySelector('.pause');

  var new_class = classes[ Math.floor( Math.random()*classes.length ) ];
  
  
  const onAudioEnd = () => {
    
    // document.querySelector('#quote_item').classList.add('out');
    // document.querySelector('#quote_item').classList.remove('in');

    // gsap.to(document.querySelector('#quote_item'), {
    //   duration: 2,
    //   y: "20%"
    // });

    gsap
      .timeline({
        onComplete: () => {
          setIsHowlerPlay(false);
          onComplete();
          document.querySelector('#quote_item').classList.remove(new_class);
        },
      }).to(document.querySelector('.pri'), {
        duration: 1.5,
        y: "-25%",
        scaleY: '1.1',
        opacity: 0,
        ease: Expo.easeIn,
      }).to(document.querySelector('.sec'), {
        duration: 1.5,
        // delay: .1,
        y: "-25%",
        scaleY: '1.1',
        opacity: 0,
        ease: Expo.easeIn,
      }, "-.1").set(refText.current, { opacity: 0 });
    };

  useEffect(() => {

    // document.querySelector('#quote_item').classList.remove('out');
    // document.querySelector('#quote_item').classList.add('in');
    
    // gsap.from(document.querySelector('#quote_item'), {
    //   duration: 6,
    //   y: "-20%"
    // });

    document.querySelector('.cursor').classList.add('planting_playing');
    document.querySelector('.home_logo').addEventListener('click', function(){
      document.querySelector('.cursor').classList.remove('planting_playing');
    });

    // split = new SplitText(refText.current);

    gsap
      .timeline()
      .set(refText.current, { opacity: 1 })
      .add(() => {
        document.querySelector('#quote_item').classList.add(new_class, 'in');
        setIsHowlerPlay(true);
      })
      .from(document.querySelector('.pri'), {
        duration: 1,
        y: "25%",
        scaleY: '1.1',
        opacity: 0,
        ease: Expo.easeOut
      });
      
      gsap.from(document.querySelector('.sec'), {
        duration: 1,
        y: "25%",
        opacity: 0,
        scaleY: '1.1',
        delay: .5,
        ease: Expo.easeOut
      });

      gsap.to(document.querySelector('.pri'), {
        duration: 4,
        delay: 1,
        backgroundSize: '100% 100%',
        ease: Linear.easeOut
      });

      gsap.to(document.querySelector('.sec'), {
        duration: 3.5,
        delay: 3.5,
        backgroundSize: '100% 100%',
        ease: Linear.easeOut
      });

    document.querySelector('#quote_item').style.width = document.querySelector('.pri').clientWidth * 3 + 'px';

      // document.querySelector('.pause').addEventListener('click', function(){
      //   gsap.pause();
      // });
      
    return () => {};
  }, [text]);

  return (
    <>
      <div ref={refText} className={cx(sty.SunshineText)} id="quote_item" dangerouslySetInnerHTML={{ __html: text}}>
        {/* {text} */}
      </div>
        {/* <div className="pause">PAUSE</div> */}
      <ReactHowler
        src={voiceFileUrl}
        playing={isHowlerPlay}
        mute={isMute}
        onEnd={onAudioEnd}
      />
    </>
  );
};

SunshineText.propTypes = {};

export default SunshineText;
